import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PwawsService } from 'src/app/pwaws.service';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public angForm: FormGroup;
    submitted = false;
    infoMessage = '';
    infoMessageerror = '';
  constructor( private registerservice: PwawsService,private fb: FormBuilder,private router:Router) { }

  ngOnInit() {

      this.angForm = this.fb.group({
  
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        });
  }
 
  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {
    this.submitted = true;

    this.registerservice.usercreate(this.angForm.value.fname,this.angForm.value.lname,this.angForm.value.email,this.angForm.value.password, '', '1')
      .pipe(first())
      .subscribe(
      data => {
       if(data.status == true) {
        this.router.navigate(['login'], {queryParams: { adduser: 'true'} });
       }else{
        this.infoMessageerror = data.message;
       }
        
      },
      error => {
      });
   
  }


}
