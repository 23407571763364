
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';

@Injectable({
providedIn: 'root'
})

export class AuthguardGuard implements CanActivate {
constructor(private router: Router ) {}
canActivate(
route: ActivatedRouteSnapshot,
state: RouterStateSnapshot): any {


  const token = localStorage.getItem('excellencepwausers');
  if(token) {

   // this.router.navigate(['/dashboard']);
   return true;
  }else{
    
    this.router.navigate(['login'], {queryParams: { Loggedinornot: 'true'} });
  }

}



}

