<div class="img-wrapper">
	
	<div class="front">
        <a [routerLink]="['/shop-detail/', product.title.replace(' ', '-')]">
            <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
                 [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
                 class="img-fluid lazy-loading" alt="">
        </a>
	</div>
	<div class="cart-box">
		<button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
		<a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
            <i class="ti-heart" aria-hidden="true"></i>
        </a>
		<a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
		
	</div>
</div>
<div class="product-detail text-center " style="padding-top:10px;">
	<div class="rating">
		
	</div>
    <a style="padding-top:10px;" [routerLink]="['/shop-detail/', product.title.replace(' ', '-')]">
			<h6>{{ product.title | titlecase }}</h6>
		</a>
    <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        
    </h4>
    
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>