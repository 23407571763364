<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <div *ngIf="adduser">
                        <div class="alert alert-success">
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ adduser }}</div>
                      </div>

                      <div *ngIf="Loggedinornot">
                        <div class="alert alert-danger">
                          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ Loggedinornot }}</div>
                      </div>
                      <div  *ngIf=submitted>
                        <div *ngIf="infoMessage">
                          <div class="alert alert-success">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessage }}</div>
                        </div>
                        <div *ngIf="infoMessageerror">
                            <div class="alert alert-danger">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessageerror }}</div>
                          </div>
                
                      </div>
                      <form [formGroup]="angForm" class="theme-form" (ngSubmit)="onsubmit()" autocomplete="off">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control"  formControlName="email" id="email" placeholder="Email" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required" >
                                     Email is required
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <input type="password" class="form-control"  formControlName="password" id="review" placeholder="Enter your password" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required" >
                                     Password is required
                                </span>
                            </div>
                        </div>
                        <button class="btn btn-solid" [disabled]="angForm.invalid" type="submit">Login</button>
                        <p style="margin-top:10px;">Want to verify your unique code ? <a  style="color:#f0b732;cursor:pointer;" (click)="openmodal()"  >Click Here</a></p>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create A Account</h6>
                    <h4> Already have unique code? <a  style="color:#f0b732;cursor:pointer;" (click)="openmodal()"  >Click Here</a> </h4>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                   <a routerLink="/register"> <button class="btn btn-solid" >Create an Account</button> </a> 

                </div>
            </div>
        </div>
    </div>

    <ng-template class="theme-modal" #newsletter let-modal>
        <div class="modal-content">
            <div class="modal-body modal7">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="modal-bg">
                                <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                           
                                
                                <div *ngIf="isFoo" class="offer-content text-center"><img src="assets/images/icon/logo.png" class="img-fluid" alt="" >
                                    <h2>Enter unique code number?</h2>
                                    <div  *ngIf=submitted>
                                        <div class="form-group col-sm-12">
                                        <div *ngIf="status;else other_content">
                                          <div class="alert alert-success"> {{ message1 }}</div>
                                        </div>
                                
                                        <ng-template #other_content> <span class="alert alert-danger"> {{ message1 }}</span> </ng-template>
                                
                                </div>
                                      </div>
                                    <form action="" [formGroup]="angForm1" (ngSubmit)="onsubmitverify()" class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                        <div class="form-group mx-sm-3">
                                            <input type="text" class="form-control" formControlName="uniquecode" name="uniquecode" id="mce-EMAIL" placeholder="Enter unique code number" required="required">
                                            <span class="text-danger"
                                            *ngIf="(registerFormControl1.uniquecode.touched || submitted) && registerFormControl1.uniquecode.errors?.required" >
                                            Unique code is required
                                        </span>
                                        </div>
                                        <div class="form-group mx-sm-3">  
                                            <button type="submit" class="btn btn-solid mr-3" id="mc-submit">Verify</button>
                                        </div>
                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</section>
<!--Section ends-->