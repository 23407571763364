import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { PwawsService } from 'src/app/pwaws.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-playgame',
  templateUrl: './playgame.component.html',
  styleUrls: ['./playgame.component.scss']
})
export class PlaygameComponent implements OnInit {
  
  closeResult: string;
  infoMessage = '';
  infourl = '';
  categorieslist = Array();
  color1:any;
  color2:any;
  color3:any;
  color4:any;
  color5:any;
  color6:any;
  myArray = Array();
  forwardname:any;
  activityname:any;
  questionarray1 = Array();
  questionarray2 = Array();
  questionarray3 = Array();
  questionarray4 = Array();
  questionarray5 = Array();
  questionarray6 = Array();
  cat1question:any;
  cat1answer1:any;
  cat1answer2:any;
  cat1answer3:any;
  cat1rightanswer:any;

  cat2question:any;
  cat2answer1:any;
  cat2answer2:any;
  cat2answer3:any;
  cat2rightanswer:any;
  questionlength:any;
  questionlength2:any;
  questionlength3:any;
  questionlength4:any;
  questionlength5:any;
  questionlength6:any;

  isShow = false;  
  isShowanswer =  false;
  constructor(private modalService: NgbModal,private router:Router,private registerservice: PwawsService) {}
  
  toggleDisplay() {
    this.isShowanswer = false;
    this.isShow = true;
    }
    toggleDisplay1() {
      this.isShow = false;
      this.isShowanswer = true;
      }
  
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnInit(): void {
    
    

    const userid = localStorage.getItem('excellencepwausers');
    this.registerservice.checkuserorder(userid)
    .pipe(first())
    .subscribe(
    data => {

     if(data.status == false) {
       this.router.navigate(['/shop']);
     }
     
    },
    error => {
    });

    this.registerservice.categorylist().pipe(first()).subscribe(
    data => {

      this.color1 = data[0].color;
      this.color2 = data[1].color;
      this.color3 = data[2].color;
      this.color4 = data[3].color;
      this.color5 = data[4].color;
      this.color6 = data[5].color;
    
    },
    error => {
    });

    this.registerservice.activitylist().pipe(first()).subscribe(
      data => {
        this.myArray = data;
      },
      error => {
      });
     
      this.registerservice.singlecategoryquestion('1').pipe(first()).subscribe(
          data => {
            this.questionarray1 = data;
          },
          error => {
       });

       this.registerservice.singlecategoryquestion('2').pipe(first()).subscribe(
          data => {
            this.questionarray2 = data;
          
          },
          error => {
       });

       this.registerservice.singlecategoryquestion('3').pipe(first()).subscribe(
        data => {
          this.questionarray3 = data;
        },
        error => {
     });

     this.registerservice.singlecategoryquestion('4').pipe(first()).subscribe(
      data => {
        this.questionarray4 = data;
      },
      error => {
   });

   this.registerservice.singlecategoryquestion('5').pipe(first()).subscribe(
    data => {
      this.questionarray5 = data;
    },
    error => {
 });

 this.registerservice.singlecategoryquestion('6').pipe(first()).subscribe(
  data => {
    this.questionarray6 = data;
  },
  error => {
});
        

  }
  openquestion(content) {
  
    
    this.questionlength = this.questionarray1.length;

    if(this.questionarray1.length === 0) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    } else {
    this.isShowanswer = false;
    this.isShow = false;
    const randomMonth = this.questionarray1[Math.floor(Math.random() * this.questionarray1.length)];
    const index: number = this.questionarray1.indexOf(randomMonth);
    
    if (this.questionarray1.length === 0) {
      this.cat1question = 'done';
    }
    this.questionarray1.splice(index, 1);
    this.cat1question = randomMonth.question;
    this.cat1answer1 = randomMonth.answer1;
    this.cat1answer2 = randomMonth.answer2;
    this.cat1answer3 = randomMonth.answer3;
    this.cat1rightanswer = randomMonth.rightanswer;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  }
  
  openquestiontwo(content) {
    
    this.questionlength2 = this.questionarray2.length;

    if(this.questionarray2.length === 0) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    } else {
      
    this.isShowanswer = false;
    this.isShow = false;
    const randomMonth = this.questionarray2[Math.floor(Math.random() * this.questionarray2.length)];
    const index: number = this.questionarray2.indexOf(randomMonth);
    
    this.questionarray2.splice(index, 1);
    this.cat1question = randomMonth.question;
    this.cat1answer1 = randomMonth.answer1;
    this.cat1answer2 = randomMonth.answer2;
    this.cat1answer3 = randomMonth.answer3;
    this.cat1rightanswer = randomMonth.rightanswer;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}

  openquestionthree(content) {
    
    this.questionlength3 = this.questionarray3.length;

    if(this.questionarray3.length === 0) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    } else {

    this.isShowanswer = false;
    this.isShow = false;
    const randomMonth = this.questionarray3[Math.floor(Math.random() * this.questionarray3.length)];
    const index: number = this.questionarray3.indexOf(randomMonth);
    
    this.questionarray3.splice(index, 1);
    this.cat1question = randomMonth.question;
    this.cat1answer1 = randomMonth.answer1;
    this.cat1answer2 = randomMonth.answer2;
    this.cat1answer3 = randomMonth.answer3;
    this.cat1rightanswer = randomMonth.rightanswer;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  }
  openquestionfour(content) {
   

    this.questionlength4 = this.questionarray4.length;

    if(this.questionlength4 === 0) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    } else {

    this.isShowanswer = false;
    this.isShow = false;
    const randomMonth = this.questionarray4[Math.floor(Math.random() * this.questionarray4.length)];
    const index: number = this.questionarray4.indexOf(randomMonth);
    
    this.questionarray4.splice(index, 1);
    this.cat1question = randomMonth.question;
    this.cat1answer1 = randomMonth.answer1;
    this.cat1answer2 = randomMonth.answer2;
    this.cat1answer3 = randomMonth.answer3;
    this.cat1rightanswer = randomMonth.rightanswer;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}

  openquestionfive(content) {
   
    this.questionlength5 = this.questionarray5.length;

    if(this.questionlength5 === 0) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    } else {
    this.isShowanswer = false;
    this.isShow = false;
    const randomMonth = this.questionarray5[Math.floor(Math.random() * this.questionarray5.length)];
    const index: number = this.questionarray5.indexOf(randomMonth);
    
    this.questionarray5.splice(index, 1);
    this.cat1question = randomMonth.question;
    this.cat1answer1 = randomMonth.answer1;
    this.cat1answer2 = randomMonth.answer2;
    this.cat1answer3 = randomMonth.answer3;
    this.cat1rightanswer = randomMonth.rightanswer;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}

  openquestionsix(content) {
   
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}
  /* openquestionone(content) {
   
    const randomMonth = this.questionarray1[Math.floor(Math.random() * this.questionarray1.length)];
    const index: number = this.questionarray1.indexOf(randomMonth);

    
    if (this.questionarray1.length === 0) {
       console.log('finish game');
    }
    this.questionarray1.splice(index, 1);
    this.question = randomMonth;


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } */

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  
  advancearray = ['2','3','4','5'];
  doanadvance(content) {
   
    const randomMonth = this.advancearray[Math.floor(Math.random() * this.advancearray.length)];
    const index: number = this.advancearray.indexOf(randomMonth);
    this.advancearray.splice(index, 1);
    
    if (this.advancearray.length === 0) {
      this.advancearray = ['2','3','4','5'];
    }
    this.forwardname = randomMonth;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  doanaction(content) {
   
    const randomMonth = this.myArray[Math.floor(Math.random() * this.myArray.length)];
    const index: number = this.myArray.indexOf(randomMonth);
    this.myArray.splice(index, 1);
    
    if (this.myArray.length === 0) {
      this.registerservice.activitylist().pipe(first()).subscribe(
        data => {
          this.myArray = data;
        },
        error => {
        });
    }
    this.activityname = randomMonth;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: 'playgamepopup',  backdrop : 'static',
    keyboard : false}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  logout() {

    window.localStorage.removeItem('excellencepwausers');
    this.router.navigate(['login']).then(() => {
      window.location.reload();
    });

   }

}
