<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="offer-content text-center"><img src="assets/images/icon/logo.png" class="img-fluid" alt="" *ngIf="!isFoo">
                                <h2 *ngIf="!isFoo">Have you purchased the game?</h2>
                                <form *ngIf="!isFoo" action="" class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <!--<input type="text" class="form-control" name="EMAIL" id="mce-EMAIL" placeholder="Enter your email" required="required">-->
                                        <!--<button type="submit" class="btn btn-solid" id="mc-submit">subscribe</button>-->
										<a class="btn btn-solid mr-3" id="yesbutton" (click)="isFoo = true"  >Yes</a>
										<a class="btn btn-solid black-btn mr-3" (click) ="purchaseno()" [routerLink]="['/shop']">No</a>
                                    </div>
                                </form>
                            </div>
							
							<div *ngIf="isFoo" class="offer-content text-center"><img src="assets/images/icon/logo.png" class="img-fluid" alt="" >
                                <h2>Enter unique code number?</h2>
                                <div  *ngIf=submitted>
                                    <div class="form-group col-sm-12">
                                    <div *ngIf="status;else other_content">
                                      <div class="alert alert-success"> {{ message1 }}</div>
                                    </div>
                            
                                    <ng-template #other_content> <span class="alert alert-danger"> {{ message1 }}</span> </ng-template>
                            
                            </div>
                                  </div>
                                <form action="" [formGroup]="angForm" (ngSubmit)="onsubmit()" class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <input type="text" class="form-control" formControlName="uniquecode" name="uniquecode" id="mce-EMAIL" placeholder="Enter unique code number" required="required">
                                        <span class="text-danger"
                                        *ngIf="(registerFormControl.uniquecode.touched || submitted) && registerFormControl.uniquecode.errors?.required" >
                                        Unique code is required
                                    </span>
                                    </div>
                                    <div class="form-group mx-sm-3">  
                                        <button type="submit" class="btn btn-solid mr-3" id="mc-submit">Verify</button>
                                    </div>
                                   
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->