import { PwawsService } from './../../pwaws.service';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router,ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  
  public faq_list = [];

  constructor( private registerservice: PwawsService) { }

  ngOnInit(): void {

    this.registerservice.getfaqs().pipe(first()).subscribe(
      data => {
        this.faq_list = data;
      },

      error => {

      });
  }

}
