<app-breadcrumb [title]="product?.title" [breadcrumb]="'Product'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <owl-carousel-o [options]="ProductDetailsMainSliderConfig" #owlCar  class="product-slick">
                    <ng-container *ngFor="let image of product.images; index as i">
                        <ng-template carouselSlide [id]="i">
                            <div>
                                <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                   [lazyLoad]="image.src" 
                                   [alt]="image.alt" class="img-fluid" [src]="image.src">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="slider-nav">
                            <owl-carousel-o [options]="ProductDetailsThumbConfig" class="product-slick">
                                <ng-container *ngFor="let image of product.images; index as i">
                                    <ng-template carouselSlide [id]="i">
                                        <div class="owl-thumb" [class.active]="i == activeSlide">
                                        <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                           [lazyLoad]="image.src" 
                                           [alt]="image.alt" class="img-fluid" [src]="image.src" (click)="owlCar.to(activeSlide = i.toString())">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 rtl-text">
                <div class="product-right">
                    <h2>{{ product?.title }}</h2>
                    <h3>
                        {{ (product?.price | discount:product) * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}
                    </h3>
                    
                    <div class="product-description border-product">
                        
                        <h6 class="product-title">quantity</h6>
                        <div class="qty-box">
                            <div class="input-group">
                            <span class="input-group-prepend">
                              
                            </span>
                            <input type="text" name="quantity" class="form-control input-number" [value]="1" disabled/>
                            <span class="input-group-prepend">
                               
                            </span>
                            </div>
                        </div>
                    </div>
                    <div class="product-buttons">
                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                        <a href="javascrip:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="buyNow(product)">buy now</a>
                    </div>
                    <div class="border-product">
                        <h6 class="product-title">product details</h6>
                        <p>{{ product.description.substring(0, 200)+'...' }}</p>
                    </div>
                    <div class="border-product">
                        <div class="product-icon">
                            <form class="">
                                <button class="wishlist-btn" (click)="addToWishlist(product)">
                                    <i class="fa fa-heart" style="border-left:none;"></i>
                                    <span class="title-font">Add To WishList</span>
                                </button>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <section class="tab-product m-0">
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                <ngb-tabset [type]="'tabs'" class="nav-material">
                    <ngb-tab>
                        <ng-template ngbTabTitle>Description</ng-template>
                        <ng-template ngbTabContent>
                            <p>{{ product.description }}</p>
                        </ng-template>
                    </ngb-tab>
                    
                    
                </ngb-tabset>
                </div>
            </div>
        </section>
    </div>
  </div>
  <app-related-product [type]="product?.type"></app-related-product>
</section>
<app-size-modal #sizeChart [product]="product"></app-size-modal>
<!-- Section ends -->