import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { PwawsService } from 'src/app/pwaws.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public angForm: FormGroup;
  submitted = false;
  status:any;
  message1 = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,private fb: FormBuilder, private registerservice: PwawsService,public router:Router) { }

  ngOnInit(): void {

    this.angForm = this.fb.group({
      uniquecode: ['', Validators.required],
      });
  }

  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {

    this.submitted = true;

    this.registerservice.verifycode(this.angForm.value.uniquecode)
      .pipe(first())
      .subscribe(
      data => {
      
       this.status = data.status;
       this.message1 = data.message;
      
       if(data.status) {

             this.router.navigate(['/login'], {queryParams: { verifycode: 'true'} });
             localStorage.setItem("newsletter", 'true');
             this.modalService.dismissAll();

       }
      /* this.router.navigate(['/login'], {queryParams: { verifycode: 'true'} });
       localStorage.setItem("newsletter", 'true');
        this.modalService.dismissAll(); */

      },
      error => {
      });
  }

  ngAfterViewInit(): void {
    if(localStorage.getItem("newsletter") !== 'true')
       this.openModal();
    localStorage.setItem("newsletter", 'true');
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, { 
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
	
  purchaseno() {
   
    this.modalService.dismissAll();
  }
  ngOnDestroy() {
   
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
	isFoo:boolean = false;
  
}
