

<!-- Home slider start-->
<section class="p-0">
  <app-slider 
    [sliders]="sliders" 
    [textClass]="'text-center'"
    [category]="'fashion'"
    [buttonText]="'shop now'"> 
  </app-slider>
</section>
<!-- Home slider End -->



<!-- Product slider start-->

<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title4">
                    <h2 class="title-inner4">Available Products</h2>
					<div class="line">
						<span>
						</span>
					</div>
                </div>
				<div class="row">
    <div class="col-lg-6 offset-lg-3">
      <div class="product-para">
        <p class="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry, 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </p>
      </div>
    </div>
  </div>
                <owl-carousel-o class="product-5 product-m no-arrow" [options]="ProductSliderConfig">
                  <ng-container *ngFor="let product of products | slice:0:8">
                    <ng-template carouselSlide>
                      <div class="product-box product-wrap">
                          <app-product-box-two 
                            [product]="product"
                            [currency]="productService?.Currency">    
                          </app-product-box-two>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>


<!-- Product slider End -->



<!-- services start-->
<div class="container">
  <section class="service border-section small-section">
    <app-services></app-services>
  </section>
</div>
<!-- services End -->


<!--  logo section start-->
<section class="section-b-space">
	<div class="title4">
        <h2 class="title-inner4">Featured IN</h2>
        <div class="line">
            <span>
            </span>
        </div>
    </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logo"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End-->

<app-cart-variation [direction]="'right'"></app-cart-variation> 


