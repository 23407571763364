<app-breadcrumb [title]="'Contact'" [breadcrumb]="'Contact'"></app-breadcrumb>
<!--section start-->
<section class=" contact-page section-b-space">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"   allowfullscreen></iframe>
            </div>
            <div class="col-lg-5">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/phone.png" alt="Generic placeholder image">
                                <h6>Contact Us</h6>
                            </div>
                            <div class="media-body">
                                <p>{{ setting.contactno }}</p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <h6>Address</h6>
                            </div>
                            <div class="media-body">
                                <p> {{ setting.address }} </p>
                            </div>
                        </li>
                        <li>
                            <div class="contact-icon">
                                <img src="assets/images/icon/email.png" alt="Generic placeholder image">
                                <h6>Email</h6>
                            </div>
                            <div class="media-body">
                                <p>{{ setting.email }}</p>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div  *ngIf=submitted>
                    <div *ngIf="infoMessage">
                      <div class="alert alert-success">
                        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessage }}</div>
                    </div>
                  </div>
                    <form [formGroup]="angForm" class="theme-form" (ngSubmit)="onsubmit()" autocomplete="off">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label for="name">First Name</label>
                            <input type="text" formControlName="fname" class="form-control" id="name" placeholder="First name" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.fname.touched || submitted) && registerFormControl.fname.errors?.required" >
                                         First Name is required
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" formControlName="lname" class="form-control" id="last-name" placeholder="Last Name" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.lname.touched || submitted) && registerFormControl.lname.errors?.required" >
                                     Last Name is required
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <label for="review">Phone number</label>
                            <input type="text" formControlName="phone" class="form-control" id="review" placeholder="Phone number" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.phone.touched || submitted) && registerFormControl.phone.errors?.required" >
                                         Phone is required
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="text" formControlName="email" class="form-control" id="email" placeholder="Email" required="">
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.email" >
                                     Please enter valid email
                                </span>
                                <span class="text-danger"
                                *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required" >
                                Email is required
                           </span>
                            </div>
                        </div>
                      
                        <div class="col-md-12">
                            <label for="review">Write Your Message</label>
                            <textarea class="form-control" formControlName="message" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                            <div class="dangerclass">
                                <span class="text-danger"
                                     *ngIf="(registerFormControl.message.touched || submitted) && registerFormControl.message.errors?.required" >
                                         Message is required
                                </span>
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <button class="btn btn-solid" [disabled]="angForm.invalid" type="submit">Send Your Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->