import { PwawsService } from 'src/app/pwaws.service';
import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { TeamSlider, TestimonialSlider } from '../../shared/data/slider';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
 
  @ViewChild('dataContainer') dataContainer: ElementRef;
  setting:any;
  constructor( private registerservice: PwawsService) { }

  ngOnInit(): void {

    this.registerservice.getpage(4).pipe(first()).subscribe(
      data => {
      /*  this.dataContainer.nativeElement.innerHTML = data.description; */
      this.setting = data.description;
       /* console.log(data.description); */
      },

      error => {

      });
  }

  public TeamSliderConfig: any = TeamSlider;
  public TestimonialSliderConfig: any = TestimonialSlider;

  // Team 
  public team = [{
    image: 'assets/images/team/user1.jpg',
    name: 'Demo User1',
    designation: 'Designer'
  }, {
    image: 'assets/images/team/user2.jpg',
    name: 'Demo User2',
    designation: 'Writer'
  }, {
    image: 'assets/images/team/user3.jpg',
    name: 'Demo User3',
    designation: 'Marketing'
  }, {
    image: 'assets/images/team/user4.jpg',
    name: 'Demo User4',
    designation: 'CEO'
  }, {
    image: 'assets/images/team/user1.jpg',
    name: 'Demo User5',
    designation: 'Developer'
 }]

}
