import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { PwawsService } from 'src/app/pwaws.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-startgame',
  templateUrl: './startgame.component.html',
  styleUrls: ['./startgame.component.scss']
})
export class StartgameComponent implements OnInit {
  infoMessage = '';
  infourl = '';

  constructor(private router:Router,private registerservice: PwawsService) { }

  ngOnInit(): void {

    const userid = localStorage.getItem('excellencepwausers');
    this.registerservice.checkuserorder(userid)
    .pipe(first())
    .subscribe(
    data => {

      this.infoMessage = data.message;
     if(data.status == true) {
      this.infourl = '/startgame';
     }else{
      this.infourl = '/shop';

     }
     
      
    },
    error => {
    });
  }
  logout() {

    window.localStorage.removeItem('excellencepwausers');
    this.router.navigate(['login']).then(() => {
      window.location.reload();
    });

   }
}
