import { Component, OnInit, Input } from '@angular/core';
import { PwawsService } from 'src/app/pwaws.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True
  //public setting: any[];

  public setting = {'address': '',
               'contactno': '',
               'email': ''
              }
  contactno:any;

  public today: number = Date.now();

  constructor( private registerservice: PwawsService) { }

  ngOnInit(): void {
   
    this.registerservice.getsettings().pipe(first()).subscribe(
      data => {
        this.setting = data;
       
      },

      error => {

      });
  }

}
