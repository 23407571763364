
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
  import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs/operators';
import { PwawsService } from 'src/app/pwaws.service';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;
  adduser:any;
  public angForm: FormGroup;
  public angForm1: FormGroup;
  submitted = false;
  submitted1 = false;
  infoMessage = '';
  infoMessageerror = '';
  Loggedinornot = '';
  isFoo:boolean = false;
  status:any;
  message1 = '';
  public closeResult: string;
  public modalOpen: boolean = false;

constructor(@Inject(PLATFORM_ID) private platformId: Object,private modalService: NgbModal,private registerservice: PwawsService,private router:Router,private route: ActivatedRoute,private fb: FormBuilder
    ) { }


      ngOnInit() {
    
        this.angForm1 = this.fb.group({
          uniquecode: ['', Validators.required],
          });

        this.angForm = this.fb.group({
  
          email: ['', [Validators.required, Validators.email]],
          password: ['', Validators.required],
          });

    this.route.queryParams.subscribe(params => {
      if(params.adduser !== undefined && params.adduser === 'true') {
          this.adduser = 'Registered successfully, you can login now';
      }
    });
    this.route.queryParams.subscribe(params => {
      if(params.verifycode !== undefined && params.verifycode === 'true') {
          this.adduser = 'Verified code successfully, you can login now';
      }
    });

    this.route.queryParams.subscribe(params => {
      if(params.Loggedinornot !== undefined && params.Loggedinornot === 'true') {
          this.Loggedinornot = 'Please login first !!';
      }
    });
    
  }
  openmodal() {
    this.isFoo = true;
    this.openModal();
    
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, { 
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  get registerFormControl() {
    return this.angForm.controls;
  }
  get registerFormControl1() {
    return this.angForm1.controls;
  }
 
  onsubmitverify() {
    
    this.submitted = true;
   
    this.registerservice.verifycode(this.angForm1.value.uniquecode)
      .pipe(first())
      .subscribe(
      data => {
     
       this.status = data.status;
       this.message1 = data.message;
      
       if(data.status) {

             this.router.navigate(['/login'], {queryParams: { verifycode: 'true'} });
             localStorage.setItem("newsletter", 'true');
             this.modalService.dismissAll();

       }
      /* this.router.navigate(['/login'], {queryParams: { verifycode: 'true'} });
       localStorage.setItem("newsletter", 'true');
        this.modalService.dismissAll(); */

      },
      error => {
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onsubmit() {
    this.submitted = true;

    this.registerservice.userlogin(this.angForm.value.email,this.angForm.value.password)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
       if(data.status == true) {
        window.localStorage.setItem('excellencepwausers' , data.userid)
        
        this.router.navigate(['dashboard']).then(() => {
          window.location.reload();
        });
       }else{
        this.infoMessageerror = data.message;

       }
       
        
      },
      error => {
      });
   
  }

}
  
