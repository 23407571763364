import { RegisterComponent } from './pages/account/register/register.component';
import { CollectionBannerComponent } from './elements/theme/collection-banner/collection-banner.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { FashionOneComponent } from './home/fashion/fashion-one/fashion-one.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/account/contact/contact.component';
import { LoginComponent } from './pages/account/login/login.component';
import { CollectionNoSidebarComponent } from './shop/collection/collection-no-sidebar/collection-no-sidebar.component';
import { ProductNoSidebarComponent } from './shop/product/sidebar/product-no-sidebar/product-no-sidebar.component';
import { CartComponent } from './shop/cart/cart.component';
import { Resolver } from './shared/services/resolver.service';
import { CheckoutComponent } from './shop/checkout/checkout.component';
import { AuthguardGuard } from './authguard.guard';
import { SuccessComponent } from './shop/checkout/success/success.component';
import { PlaygameComponent } from './playgame/playgame.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { StartgameComponent } from './startgame/startgame.component';
import { UpdatepasswordComponent } from './updatepassword/updatepassword.component';
import { NewauthguardGuard } from './newauthguard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    component: FashionOneComponent
  },

  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
   { 
    path: 'faq', 
    component: FaqComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'aboutus', 
    component: AboutUsComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'contact', 
    component: ContactComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'login', 
    component: LoginComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'register', 
    component: RegisterComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'shop', 
    component: CollectionNoSidebarComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'cart',
    component: CartComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'checkout',
    canActivate: [AuthguardGuard],
    component: CheckoutComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'dashboard',
    canActivate: [AuthguardGuard],
    component: StartgameComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'startnewgame',
    component: StartgameComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'startgame',
    canActivate: [AuthguardGuard],
    component: PlaygameComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  {
    path: 'updatepassword',
    canActivate: [AuthguardGuard],
    component: UpdatepasswordComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  {
    path: 'checkout/success',
    component: SuccessComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  {
    path: 'shop-detail/:slug',
    component: ProductNoSidebarComponent,
    resolve: {
      data: Resolver
    },
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) 
  },
  
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
