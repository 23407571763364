import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PwawsService } from 'src/app/pwaws.service';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  public setting = {'address': '',
               'contactno': '',
               'email': ''
              }
    public angForm: FormGroup;
    submitted = false;
    infoMessage = '';
  constructor( private registerservice: PwawsService,private fb: FormBuilder,private router:Router) { }


  ngOnInit() {
    this.registerservice.getsettings().pipe(first()).subscribe(
      data => {
        this.setting = data;
      },

      error => {

      });

      this.angForm = this.fb.group({
  
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        message: ['', Validators.required],
        });
  }
 
  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {
    this.submitted = true;

    this.registerservice.addcontact(this.angForm.value.fname,this.angForm.value.lname,this.angForm.value.email,this.angForm.value.phone,this.angForm.value.message)
      .pipe(first())
      .subscribe(
      data => {
        this.infoMessage = data.message;
        this.angForm.reset();
      },
      error => {
      });
   
  }

}
