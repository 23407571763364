import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as glob from './globals';


@Injectable({
  providedIn: 'root'
})
export class PwawsService {

  baseUrl:string =  glob.baseurl;
  Users:  any;
  constructor(private httpClient : HttpClient) { }

  public getfaqs() {
    return this.httpClient.post<any>(this.baseUrl + '/faqsection.php', { })
      .pipe(map((Users: any) => {
        return Users;
    }));

  }
  public getsettings() {
    return this.httpClient.post<any>(this.baseUrl + '/singlesetting.php', { })
      .pipe(map((Users: any) => {
        return Users;
    }));

  }
  public addcontact(fname:any,lname:any,email:any,phone:any,message:any) {
    return this.httpClient.post<any>(this.baseUrl + '/add-contact.php', {fname,lname,email,phone,message })
      .pipe(map((Users: any) => {
        return Users;
    }));

  }

    public getpage(id: any) {
          return this.httpClient.post<any>(this.baseUrl + '/singlepage.php', {id })
            .pipe(map((Users: any) => {
              return Users;
          }));
        }

        public usercreate(name: any,lname: any,email: any,pwd: any,code: any,frontadmin:any) {

          return this.httpClient.post<any>(this.baseUrl + '/add-userfront.php', { name,lname,email, pwd , code,frontadmin})
          .pipe(map((Users: any) => {
            console.log(Users);
          return Users;
          }));
        }
        public userlogin(email: any,password: any) {
          return this.httpClient.post<any>(this.baseUrl + '/frontlogin.php', { email, password })
            .pipe(map((Users: any) => {
              return Users;
          }));
    }

    public userorder(userid:any,firstname: any,lastname: any,phone: any,country: any,address: any,town:any,state:any,postalcode:any,totalprice:any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-order.php', { userid,firstname,lastname,phone,country, address ,town,state,postalcode,totalprice})
      .pipe(map((Users: any) => {
        
      return Users;
      }));
    }

    public userorderdetail(orderid:any,productid: any,qty: any,price: any,totalprice:any) {

      return this.httpClient.post<any>(this.baseUrl + '/add-orderdetail.php', { orderid,productid,qty,price,totalprice})
      .pipe(map((Users: any) => {
       
      return Users;
      }));
    }

    public verifycode(code: any) {
      return this.httpClient.post<any>(this.baseUrl + '/verifycode.php', { code })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }

    public checkuserorder(userid: any) {
      return this.httpClient.post<any>(this.baseUrl + '/checkuserorder.php', { userid })
        .pipe(map((Users: any) => {
          return Users;
      }));
    }
    public updatepassword(userid:any,oldpassword: any,newpassword: any) {
      return this.httpClient.post<any>(this.baseUrl + '/updatepassword.php', { userid, oldpassword, newpassword })
        .pipe(map((Users: any) => {
          return Users;
      }));
  }

  public categorylist() {
    return this.httpClient.post<any>(this.baseUrl + '/categorylist.php', { })
      .pipe(map((Users: any) => {
        return Users;
    }));
  }

  public singlecategoryquestion(categoryid:any) {
    return this.httpClient.post<any>(this.baseUrl + '/singlecategoryquestion.php', {categoryid })
      .pipe(map((Users: any) => {
        return Users;
    }));
 }
 public activitylist() {
  return this.httpClient.post<any>(this.baseUrl + '/activityaction.php', { })
    .pipe(map((Users: any) => {
      return Users;
  }));
}


}
