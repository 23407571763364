<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <div  *ngIf=submitted>
                        <div *ngIf="infoMessage">
                          <div class="alert alert-success">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessage }}</div>
                        </div>
                        <div *ngIf="infoMessageerror">
                            <div class="alert alert-danger">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessageerror }}</div>
                          </div>
                
                      </div>
                    <form [formGroup]="angForm" class="theme-form" (ngSubmit)="onsubmit()" autocomplete="off">
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">First Name</label>
                                <input type="text" class="form-control"  formControlName="fname" id="fname" placeholder="First Name" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.fname.touched || submitted) && registerFormControl.fname.errors?.required" >
                                             First Name is required
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="review">Last Name</label>
                                <input type="text" class="form-control" id="lname"  formControlName="lname" placeholder="Last Name" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.lname.touched || submitted) && registerFormControl.lname.errors?.required" >
                                         Last Name is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">email</label>
                                <input type="text" class="form-control" id="email"  formControlName="email" placeholder="Email" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.email" >
                                         Please enter valid email
                                    </span>
                                    <span class="text-danger"
                                    *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required" >
                                    Email is required
                               </span>
                                </div>
                            
                            </div>
                            <div class="col-md-6">
                                <label for="review">Password</label>
                                <input type="password" class="form-control" id="review"  formControlName="password" placeholder="Enter your password" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required" >
                                         Password is required
                                    </span>
                                </div>
                            </div>
                            <button class="btn btn-solid" [disabled]="angForm.invalid" type="submit">create Account</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->