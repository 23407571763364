<app-breadcrumb [title]="'Products'" [breadcrumb]="'Products'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="top-banner-wrapper">
                                    
                                    <div class="top-banner-content small-section">
                                        <h5>Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry.</h5>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s, when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book. It has survived not only five
                                            centuries, but also the leap into electronic typesetting, remaining
                                            essentially unchanged. It was popularised in the 1960s with the release
                                            of Letraset sheets containing Lorem Ipsum passages, and more recently
                                            with desktop publishing software like Aldus PageMaker including versions
                                            of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div>
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid 
                                        [products]="products"
                                        [paginate]="paginate"
                                        [layoutView]="layoutView"
                                        [sortBy]="sortBy"
                                        (setGrid)="updateGridLayout($event)"
                                        (setLayout)="updateLayoutView($event)"
                                        (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box"
                                                [ngClass]="grid"
                                                *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-box-two 
                                                        [product]="product" 
                                                        [currency]="productService?.Currency"
                                                       
                                                        >
                                                    </app-product-box-two>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <app-pagination
                                        [products]="products"
                                        [paginate]="paginate"
                                        (setPage)="setPage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->