<app-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-breadcrumb>
<section class="section-b-space">
    <div class="container padding-cls">
        <div class="checkout-page">
            <div class="checkout-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-title">
                                <h3>Billing Details</h3>
                            </div>
                            <div class="row check-out">
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">First Name</div>
                                    <input type="text" name="firstname" [formControl]="checkoutForm.controls['firstname']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.required" class="text text-danger">
                                    First Name is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.pattern" class="text text-danger">
                                    First Name must be an alphabates.
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Last Name </div>
                                    <input type="text" name="lastname" [formControl]="checkoutForm.controls['lastname']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.required" class="text text-danger">
                                    Last Name is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.pattern" class="text text-danger">
                                    Last Name must be an alphabates.
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Phone</div>
                                    <input type="text" name="phone" [formControl]="checkoutForm.controls['phone']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.required" class="text text-danger">
                                     Phone No is required.
                                    </div>
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.pattern" class="text text-danger">
                                      Phone No is must be number.
                                    </div>
                                </div>
                              
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Country</div>
                                    <select name="country" [formControl]="checkoutForm.controls['country']">
                                        <option value="" selected>Select Country</option>
                                        <option value="UK">UK</option>
                                        <option value="USA">USA</option>
                                    </select>
                                    <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required" class="text text-danger">
                                     Country is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Address</div>
                                    <input type="text" name="address" [formControl]="checkoutForm.controls['address']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.required" class="text text-danger">
                                     Address is required
                                    </div>
                                    <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.maxlength" class="text text-danger">
                                      Maximum 50 character
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Town/City</div>
                                    <input type="text" name="town" [formControl]="checkoutForm.controls['town']" value="" placeholder="" autocomplete="off">
                                     <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.required" class="text text-danger">
                                     City is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">State</div>
                                    <input type="text" name="state" [formControl]="checkoutForm.controls['state']" value="" placeholder="" autocomplete="off">
                                     <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required" class="text text-danger">
                                     State is required
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">Postal Code</div>
                                    <input type="text" name="postalcode" [formControl]="checkoutForm.controls['postalcode']" value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.postalcode.touched && checkoutForm.controls.postalcode.errors?.required" class="text text-danger">
                                     Postalcode is required
                                    </div>
                                </div>
                            </div>
                          <!--<div class="row ">
                            <div class="col-12">
                              <div class="stripe-section">
                                <h5>stripe js</h5>
                                <div class="content">
                                  <h5>dummy test</h5>
                                  <table>
                                    <tr>
                                      <td>cart number</td>
                                      <td>4242424242424242</td>
                                    </tr>
                                    <tr>
                                      <td>mm/yy</td>
                                      <td>2/2020</td>
                                    </tr>
                                    <tr>
                                      <td>cvc</td>
                                      <td>2222</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row margin-cls">
                            <div class="col-12">
                              <div class="stripe-section">
                                <h5>paypal</h5>
                                <div class="content">
                                  <h5>dummy test</h5>
                                  <table>
                                    <tr>
                                      <td>cart number</td>
                                      <td>4152521541244</td>
                                    </tr>
                                    <tr>
                                      <td>mm/yy</td>
                                      <td>11/18</td>
                                    </tr>
                                    <tr>
                                      <td>cvc</td>
                                      <td>521</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>-->
                        </div>

                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-details">
                                <div class="order-box">
                                    <div class="title-box">
                                        <div>Product <span> Total</span></div>
                                    </div>
                                    <ul class="qty" *ngIf='products.length'>
                                        <li *ngFor="let product of products">
                                        	 {{ product.title }} × {{ product.quantity }}
                                        	<span>
                                                {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                            </span>
                                        </li>
                                    </ul>
                                    <ul class="qty" *ngIf='!products.length'>
                                        <li class="empty-checkout"> There are no products in cart </li>
                                    </ul>
                                    <ul class="sub-total">
                                        <li>
                                        	Subtotal <span class="count">{{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</span>
                                        </li>
                                        <!--<li *ngIf='products.length'>
                                        	Shipping
                                         <div class="shipping">
                                            <div class="shopping-option">
                                                <input type="radio" name="shipping" checked="checked" id="free-shipping">
                                                <label for="free-shipping">Shipping</label>
                                            </div>
                                            <div class="shopping-option">
                                                <input type="radio"  name="shipping" id="local-pickup">
                                                <label for="local-pickup">Local Pickup</label>
                                            </div>
                                         </div>
                                        </li>-->
                                    </ul>
                                    <ul class="total">
                                        <li>Total <span class="count">{{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</span></li>
                                    </ul>
                                </div>

                                <div class="payment-box" *ngIf='products.length'>
                                    <div class="upper-box">
                                        <div class="payment-options">
                                            <ul>
                                                <!--<li>
                                                    <div class="radio-option">
                                                        <input type="radio" [(ngModel)]="payment" [value]="'Stripe'"  name="payment-group" id="payment-2">
                                                        <label for="payment-2">Stripe
                                                            <span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                                                    </div>
                                                </li>-->
                                                <li>
                                                    <div class="radio-option paypal">
                                                        <!--<input type="radio" [(ngModel)]="payment" [value]="'Paypal'" name="payment-group" id="payment-3">-->
                                                        <label for="payment-3"><span class="image"><img src="assets/images/paypal.png" alt="" /></span></label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="text-right">
                                      <!---- <button type="submit" class="btn-solid btn" [disabled]="!checkoutForm.valid" (click)="stripeCheckout()" *ngIf="payment == 'Stripe'">
                                           Place Order
                                        </button> -->
                                        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>