<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Dashboard</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active" >Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Change Password</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar"><a class="popup-btn">my account</a></div>
                <div class="dashboard-left">
                    <div class="collection-mobile-back"><span class="filter-back"><i class="fa fa-angle-left" aria-hidden="true"></i> back</span></div>
                    <div class="block-content">
                        <ul>
                            <li><a routerLink="/dashboard">Start game</a></li>
                            <li class="active"><a routerLink="/updatepassword">Change Password</a></li>
                            <li class="last"><a routerLink=""  (click)="logout()">Log Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
               
                <div class="theme-card">
                    <h3>change password</h3>
                    <div  *ngIf=submitted>
                        <div *ngIf="infoMessage">
                          <div class="alert alert-success">
                            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessage }}</div>
                        </div>
                        <div *ngIf="infoMessageerror">
                            <div class="alert alert-danger">
                              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> {{ infoMessageerror }}</div>
                          </div>
                
                      </div>
                    <form [formGroup]="angForm" class="theme-form" (ngSubmit)="onsubmit()" autocomplete="off">
                        <div class="form-row">
                            <div class="col-md-12">
                                <label for="review">Old Password</label>
                                <input type="password" class="form-control" id="review"  formControlName="oldpassword" placeholder="Enter your old password" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.oldpassword.touched || submitted) && registerFormControl.oldpassword.errors?.required" >
                                          Old Password is required
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <label for="review">New Password</label>
                                <input type="password" class="form-control" id="review"  formControlName="newpassword" placeholder="Enter your new password" required="">
                                <div class="dangerclass">
                                    <span class="text-danger"
                                         *ngIf="(registerFormControl.newpassword.touched || submitted) && registerFormControl.newpassword.errors?.required" >
                                         New Password is required
                                    </span>
                                </div>
                            </div>
                           
                            <button class="btn btn-solid" [disabled]="angForm.invalid" type="submit">Update password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->