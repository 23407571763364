import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { PwawsService } from 'src/app/pwaws.service';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.scss']
})
export class UpdatepasswordComponent implements OnInit {

  public angForm: FormGroup;
    submitted = false;
    infoMessage = '';
    infoMessageerror = '';
  constructor( private registerservice: PwawsService,private fb: FormBuilder,private router:Router) { }

  ngOnInit() {

      this.angForm = this.fb.group({
  
        oldpassword: ['', Validators.required],
        newpassword: ['', Validators.required],
        });
  }
 
  get registerFormControl() {
    return this.angForm.controls;
  }

  onsubmit() {
   
    this.submitted = true;
    const userid = localStorage.getItem('excellencepwausers');
    this.registerservice.updatepassword(userid,this.angForm.value.oldpassword,this.angForm.value.newpassword)
      .pipe(first())
      .subscribe(
      data => {
       if(data.status == true) {
        this.infoMessage = data.message;
       }else{
        this.infoMessageerror = data.message;
       }
      },
      error => {
      });
   
  }

  logout() {

    window.localStorage.removeItem('excellencepwausers');
    this.router.navigate(['/login']);

   }


}
