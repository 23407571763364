<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Dashboard</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active" >Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<section class="section-b-space" >
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar"><a class="popup-btn">my account</a></div>
                <div class="dashboard-left">
                    <div class="collection-mobile-back"><span class="filter-back"><i class="fa fa-angle-left" aria-hidden="true"></i> back</span></div>
                    <div class="block-content">
                        <ul>
                            <li class="active"><a routerLink="dashboard">Start game</a></li>
                            <li><a routerLink="/updatepassword">Change Password</a></li>
                            <li class="last"><a routerLink="" (click)="logout()">Log Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                     
                        <div class="box-account text-center box-info">

                                 <a routerLink="{{ infourl }}" class="btn btn-solid mr-3" id="startgame"> {{ infoMessage}}</a>
                                     
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
           
        
        </div>
   
</section>