<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Dashboard</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home" routerLinkActive="router-link-active" >Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<section class="section-b-space" >
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar"><a class="popup-btn">my account</a></div>
                <div class="dashboard-left">
                    <div class="collection-mobile-back"><span class="filter-back"><i class="fa fa-angle-left" aria-hidden="true"></i> back</span></div>
                    <div class="block-content">
                        <ul>
                            <li class="active"><a routerLink="/dashboard">Start game</a></li>
                            <li><a routerLink="/updatepassword">Change Password</a></li>
                            <li class="last"><a routerLink="" (click)="logout()">Log Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="dashboard-right">
                    <div class="dashboard">
                        <div class="page-title">
                            <h2>Start game</h2>
                        </div>
                     
                        <div class="box-account box-info">
                            <div class="container">
                             
                                <div class="row">
                                   
                                    <div class="col-lg-3 col-md-3 col-xs-6" (click)="openquestion(mymodalone)" id="mainblock">
                                        <div class="box" style="background-color: {{ color1 }}">
                                        <div style="color:#fff;" ><p>1</p> <span class="ondice">(on dice)</span></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-xs-6"  id="mainblock">
                                       
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6"  (click)="openquestiontwo(mymodaltwo)"  id="mainblock">
                                        <div class="box" style="background-color:  {{ color2 }}">
                                        <div style="color:#fff;" ><p>2</p> <span class="ondice">(on dice)</span></div>
                                    </div>
                                </div>
                                    <div class="col-lg-1 col-md-2 col-xs-6" id="mainblock">
                                       
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6" (click)="openquestionthree(mymodalthree)"  id="mainblock">
                                        <div class="box" style="background-color:  {{ color3 }}">
                                            <div style="color:#fff;" ><p>3</p> <span class="ondice">(on dice)</span></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-3 col-xs-6" id="mainblock">
                                     
                                    </div>
                                   
                                    
                                </div>
                                <div class="row">
                                   
                                    <div class="col-lg-3 col-md-3 col-xs-6" (click)="openquestionfour(mymodalfour)" id="mainblock">
                                        <div class="box" style="background-color:   {{ color4 }}">
                                        <div style="color:#fff;" ><p>4</p> <span class="ondice">(on dice)</span></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-xs-4"  id="mainblock">
                                       
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6"  (click)="openquestionfive(mymodalfive)" id="mainblock">
                                        <div class="box" style="background-color:  {{ color5 }} ">
                                        <div style="color:#fff;" ><p>5</p> <span class="ondice">(on dice)</span></div>
                                    </div>
                                </div>
                                    <div class="col-lg-1 col-md-2 col-xs-6" id="mainblock">
                                       
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-xs-6" id="mainblock" (click)="openquestionsix(mymodalsix)">
                                        <div class="box" style="background-color:  {{ color6 }}">
                                            <div style="color:#fff;" ><p>6</p> <span class="ondice">(on dice)</span></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-xs-6" id="mainblock">
                                     
                                    </div>
                                   
                                     </div>  
                                     <div style="text-align: center;">  

                                        <a class="btn btn-solid mr-3" id="newbutton" (click)="doanaction(doactionmodal)"style="text-align: center;margin-top: 30px;"> Do An Action </a>
                                        <a class="btn btn-solid mr-3" id="newbutton" (click)="doanadvance(advancemodal)"  style="margin-top: 30px;"> Advance </a>

                                     </div>
                                     
                                </div>
                            
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #restartgame let-modal>
                <div class="modal-content1" style="border: 5px solid #f0b732;background-color: #f0b732;color: #fff;border-radius:15px;">
                    <div class="modal-body" style="font-size: 35px !important;" >
                        <a class="btn btn-outline-dark custombutton" href="/dashboard">Start a new Game</a>
                    </div>
                <div>
                    
                </div>
                </div>
              </ng-template>
            <ng-template #doactionmodal let-modal>
                <div class="modal-content1" style="border: 5px solid #f0b732;background-color: #f0b732;color: #fff;border-radius:15px;">
                    <div class="modal-body" style="font-size: 35px !important;" >
                       {{activityname}}
                    </div>
                <div>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
                </div>
              </ng-template>
              <ng-template #advancemodal let-modal>
                <div class="modal-content1" style="border: 5px solid #f0b732;background-color: #f0b732;color: #fff;border-radius:15px;">
                    <div class="modal-body" style="font-size: 35px !important;" >
                       {{forwardname}}
                    </div>
                <div>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
                </div>
              </ng-template>

              <ng-template #mymodalone let-modal>
              
                <div class="modal-content" *ngIf="questionlength == 0" style="border: 5px solid #e94e1a;background-color: {{ color1 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" >
                        <a class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')" href="/#/startnewgame">Start a new Game</a>
                    
                    </div>
                </div>
                    <div class="modal-content" *ngIf="questionlength !== 0" style="border: 5px solid #e94e1a;background-color: {{ color1 }};color: #fff;border-radius:15px;">
                    <div class="modal-body">
                       {{cat1question}}  
                      <br><span><i class="fa fa-question-circle" style="font-size:30px;cursor: pointer;" (click) = toggleDisplay1()  title="Help" aria-hidden="true"></i></span>
                        <ul class="popupul"  *ngIf = "isShow">
                       
                            <li [ngStyle]="{'color': cat1rightanswer==='answer1' ? '#e94e1a' : '#fff' }" style="margin-top:5px;">{{cat1answer1}} <i *ngIf="cat1rightanswer==='answer1'" class="fa fa-check-circle"></i> </li>
                            <li [ngStyle]="{'color': cat1rightanswer==='answer2' ? '#e94e1a' : '#fff' }">{{cat1answer2}}   <i *ngIf="cat1rightanswer==='answer2'" class="fa fa-check-circle"></i></li>
                            <li [ngStyle]="{'color': cat1rightanswer==='answer3' ? '#e94e1a' : '#fff' }">{{cat1answer3}}  <i *ngIf="cat1rightanswer==='answer3'" class="fa fa-check-circle"></i></li>
                            
                          </ul>
                          <ul class="popupul" *ngIf = "isShowanswer">
                       
                            <li style="margin-top:5px;">{{cat1answer1}}  </li>
                            <li>{{cat1answer2}}  </li>
                            <li>{{cat1answer3}} </li>
                            
                          </ul>
                    </div>
                   
                     <div>
                    <button type="button" (click) = toggleDisplay() class="btn btn-outline-dark custombutton">Display Answer</button>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
            </div>
              </ng-template>
              <ng-template #mymodaltwo let-modal >
               
                <div class="modal-content" *ngIf="questionlength2 == 0" style="border: 5px solid #ffdd0e;background-color: {{ color2 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" >
                        <a class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')" href="/#/startnewgame">Start a new Game</a>
                    
                    </div>
                </div>
              
                <div class="modal-content" *ngIf="questionlength2 !== 0" style="border: 5px solid #ffdd0e;background-color:{{ color2 }};color: #fff;border-radius:15px;">

                    <div class="modal-body" >
                        {{cat1question}}  
                        <br><span><i class="fa fa-question-circle" style="font-size:30px;cursor: pointer;" (click) = toggleDisplay1()  title="Help" aria-hidden="true"></i></span>
                         <ul class="popupul"  *ngIf = "isShow">
                        
                             <li [ngStyle]="{'color': cat1rightanswer==='answer1' ? '#ffdd0e' : '#fff' }" style="margin-top:5px;">{{cat1answer1}} <i *ngIf="cat1rightanswer==='answer1'" class="fa fa-check-circle"></i> </li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer2' ? '#ffdd0e' : '#fff' }">{{cat1answer2}}   <i *ngIf="cat1rightanswer==='answer2'" class="fa fa-check-circle"></i></li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer3' ? '#ffdd0e' : '#fff' }">{{cat1answer3}}  <i *ngIf="cat1rightanswer==='answer3'" class="fa fa-check-circle"></i></li>
                             
                           </ul>
                           <ul class="popupul" *ngIf = "isShowanswer">
                        
                             <li style="margin-top:5px;">{{cat1answer1}}  </li>
                             <li>{{cat1answer2}}  </li>
                             <li>{{cat1answer3}} </li>
                             
                           </ul>
                     </div>
                   
                     <div>
                    <button type="button"  (click) = toggleDisplay() class="btn btn-outline-dark custombutton">Display Answer</button>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
            </div>
              </ng-template>

              <ng-template #mymodalthree let-modal >
               
                <div class="modal-content" *ngIf="questionlength3 == 0" style="border: 5px solid #94c120;background-color: {{ color3 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" >
                        <a class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')" href="/#/startnewgame">Start a new Game</a>
                    
                    </div>
                </div>

                <div class="modal-content" *ngIf="questionlength3 !== 0" style="border: 5px solid #94c120;background-color: {{ color3 }};color: #fff;border-radius:15px;">

                    <div class="modal-body" >
                        {{cat1question}}  
                        <br><span><i class="fa fa-question-circle" style="font-size:30px;cursor: pointer;" (click) = toggleDisplay1()  title="Help" aria-hidden="true"></i></span>
                         <ul class="popupul"  *ngIf = "isShow">
                        
                             <li [ngStyle]="{'color': cat1rightanswer==='answer1' ? '#94c120' : '#fff' }" style="margin-top:5px;">{{cat1answer1}} <i *ngIf="cat1rightanswer==='answer1'" class="fa fa-check-circle"></i> </li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer2' ? '#94c120' : '#fff' }">{{cat1answer2}}   <i *ngIf="cat1rightanswer==='answer2'" class="fa fa-check-circle"></i></li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer3' ? '#94c120' : '#fff' }">{{cat1answer3}}  <i *ngIf="cat1rightanswer==='answer3'" class="fa fa-check-circle"></i></li>
                             
                           </ul>
                           <ul class="popupul" *ngIf = "isShowanswer">
                        
                             <li style="margin-top:5px;">{{cat1answer1}}  </li>
                             <li>{{cat1answer2}}  </li>
                             <li>{{cat1answer3}} </li>
                             
                           </ul>
                     </div>
                   
                     <div>
                    <button type="button" (click) = toggleDisplay()  class="btn btn-outline-dark custombutton">Display Answer</button>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
            </div>
              </ng-template>

              <ng-template #mymodalfour let-modal >
               
                <div class="modal-content" *ngIf="questionlength4 == 0" style="border: 5px solid #964e99;background-color: {{ color4 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" >
                        <a class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')" href="/#/startnewgame">Start a new Game</a>
                    
                    </div>
                </div>
              
            
                <div class="modal-content" *ngIf="questionlength4 !== 0" style="border: 5px solid #964e99;background-color: {{ color4 }};color: #fff;border-radius:15px;">

                    <div class="modal-body" >
                        {{cat1question}}  
                        <br><span><i class="fa fa-question-circle" style="font-size:30px;cursor: pointer;" (click) = toggleDisplay1()  title="Help" aria-hidden="true"></i></span>
                         <ul class="popupul"  *ngIf = "isShow">
                        
                             <li [ngStyle]="{'color': cat1rightanswer==='answer1' ? '#964e99' : '#fff' }" style="margin-top:5px;">{{cat1answer1}} <i *ngIf="cat1rightanswer==='answer1'" class="fa fa-check-circle"></i> </li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer2' ? '#964e99' : '#fff' }">{{cat1answer2}}   <i *ngIf="cat1rightanswer==='answer2'" class="fa fa-check-circle"></i></li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer3' ? '#964e99' : '#fff' }">{{cat1answer3}}  <i *ngIf="cat1rightanswer==='answer3'" class="fa fa-check-circle"></i></li>
                             
                           </ul>
                           <ul class="popupul" *ngIf = "isShowanswer">
                        
                             <li style="margin-top:5px;">{{cat1answer1}}  </li>
                             <li>{{cat1answer2}}  </li>
                             <li>{{cat1answer3}} </li>
                             
                           </ul>
                     </div>
                   
                     <div>
                    <button type="button" (click) = toggleDisplay()  class="btn btn-outline-dark custombutton">Display Answer</button>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
            </div>
              </ng-template>
              <ng-template #mymodalfive let-modal >
                             
                <div class="modal-content" *ngIf="questionlength5 == 0" style="border: 5px solid #006c9d;background-color: {{ color5 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" >
                        <a class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')" href="/#/startnewgame">Start a new Game</a>
                    
                    </div>
                </div>
              
            
                <div class="modal-content" *ngIf="questionlength5 !== 0" style="border: 5px solid #006c9d;background-color: {{ color5 }};color: #fff;border-radius:15px;">

                    <div class="modal-body" >
                        {{cat1question}}  
                        <br><span><i class="fa fa-question-circle" style="font-size:30px;cursor: pointer;" (click) = toggleDisplay1()  title="Help" aria-hidden="true"></i></span>
                         <ul class="popupul"  *ngIf = "isShow">
                        
                             <li [ngStyle]="{'color': cat1rightanswer==='answer1' ? '#006c9d' : '#fff' }" style="margin-top:5px;">{{cat1answer1}} <i *ngIf="cat1rightanswer==='answer1'" class="fa fa-check-circle"></i> </li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer2' ? '#006c9d' : '#fff' }">{{cat1answer2}}   <i *ngIf="cat1rightanswer==='answer2'" class="fa fa-check-circle"></i></li>
                             <li [ngStyle]="{'color': cat1rightanswer==='answer3' ? '#006c9d' : '#fff' }">{{cat1answer3}}  <i *ngIf="cat1rightanswer==='answer3'" class="fa fa-check-circle"></i></li>
                             
                           </ul>
                           <ul class="popupul" *ngIf = "isShowanswer">
                        
                             <li style="margin-top:5px;">{{cat1answer1}}  </li>
                             <li>{{cat1answer2}}  </li>
                             <li>{{cat1answer3}} </li>
                             
                           </ul>
                     </div>
                   
                     <div>
                    <button type="button" (click) = toggleDisplay()  class="btn btn-outline-dark custombutton">Display Answer</button>
                    <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                </div>
            </div>
            
              </ng-template>
              <ng-template #mymodalsix let-modal >
                 
                <div class="modal-content" style="border: 5px solid #ebc400;background-color: {{ color6 }};color: #fff;border-radius:15px;">
                  
                    <div class="modal-body" style="font-size: 35px !important;" >
                        Miss a Go
                     </div>
                    <div>
                        <button type="button" class="btn btn-outline-dark custombutton" (click)="modal.close('Save click')">Roll Dice</button>
                    </div>
                </div>
    
              </ng-template>

             
             
        </div>
   
</section>