<app-breadcrumb [title]="'Cart'" [breadcrumb]="'Cart'"></app-breadcrumb>
<!-- section start -->
<section class="cart-section section-b-space">
    <div class="container">
        <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!products.length">
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>Your Cart is Empty</strong></h3>
        <h4>Add something to make me happy :)</h4>
        </div>
        <div class="row" *ngIf="products.length">
        <div class="col-sm-12">
            <table class="table cart-table table-responsive-xs">
                <thead>
                    <tr class="table-head">
                        <th scope="col">image</th>
                        <th scope="col">product name</th>
                        <th scope="col">price</th>
                        <th scope="col">quantity</th>
                        <th scope="col">action</th>
                        <th scope="col">total</th>
                    </tr>
                </thead>
                <tbody *ngFor="let product of products">
                    <tr>
                        <td>
                            <a [routerLink]="['/shop-detail/', product.title.replace(' ', '-')]">
                                <img [src]="product.images[0].src" [alt]="product.images[0].alt">
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['/shop-detail/', product.title.replace(' ', '-')]">{{ product.title | titlecase }}</a>
                            <div class="mobile-cart-content row">
                                <div class="col-xs-3">
                                    <div class="qty-box">
                                        <div class="input-group">
                                            <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="product.quantity">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-3">
                                    <h2 class="td-color">
                                        {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                    </h2>
                                </div>
                                <div class="col-xs-3">
                                    <h2 class="td-color">
                                        <a (click)="removeItem(product)" class="icon">
                                            <i class="ti-close"></i>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                        </td>
                        <td>
                            <h2>
                                {{ (product.price | discount:product) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                            </h2>
                        </td>
                        <td>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                       
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number" disabled [(ngModel)]="product.quantity">
                                    <span class="input-group-prepend">
                                        
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                            <i class="ti-close"></i>
                        </a>
                        </td>
                        <td>
                            <h2 class="td-color">
                                {{ ((product.price | discount:product) * product.quantity) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                            </h2>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table cart-table table-responsive-md">
                <tfoot>
                    <tr>
                        <td>total price :</td>
                        <td>
                            <h2> {{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</h2>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        </div>
        <div class="row cart-buttons" *ngIf='products.length'>
            <div class="col-6">
                <a [routerLink]="['/shop']" class="btn btn-solid">continue shopping</a>
            </div>
            <div class="col-6">
                <a [routerLink]="['/checkout']" class="btn btn-solid">check out</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->