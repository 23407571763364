import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { environment } from '../../../environments/environment';
import { Product } from "../../shared/classes/product";
import { ProductService } from "../../shared/services/product.service";
import { OrderService } from "../../shared/services/order.service";
import { PwawsService } from 'src/app/pwaws.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public checkoutForm:  FormGroup;
  public products: Product[] = [];
  public payPalConfig ? : IPayPalConfig;
  public payment: string = 'Paypal';
  public amount:  any;
  paypalclientid:any;
  productid:any;
  price:any;
  quantity:any;
  totalprice:any;


  constructor(private fb: FormBuilder,
    public productService: ProductService,
    private orderService: OrderService, private registerservice: PwawsService) { 
    this.checkoutForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      address: ['', [Validators.required, Validators.maxLength(50)]],
      country: ['', Validators.required],
      town: ['', Validators.required],
      state: ['', Validators.required],
      postalcode: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.productService.cartItems.subscribe(response => this.products = response);
    this.getTotal.subscribe(amount => this.amount = amount);
    this.initConfig();
  }

  public get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  // Stripe Payment Gateway
  stripeCheckout() {
    var handler = (<any>window).StripeCheckout.configure({
      key: environment.stripe_token, // publishble key
      locale: 'auto',
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        this.orderService.createOrder();
      }
    });
    handler.open({
      name: 'Multikart',
      description: 'Online Fashion Store',
      amount: this.amount * 100
    }) 
  }

  // Paypal Payment Gateway
 /* private initConfig(): void {
    this.payPalConfig = {
        currency: this.productService.Currency.currency,
        clientId: environment.paypal_token,
        createOrderOnClient: (data) => < ICreateOrderRequest > {
          intent: 'CAPTURE',
          purchase_units: [{
              amount: {
                currency_code: this.productService.Currency.currency,
                value: this.amount,
                breakdown: {
                    item_total: {
                        currency_code: this.productService.Currency.currency,
                        value: this.amount
                    }
                }
              }
          }]
      },
        advanced: {
            commit: 'true',
            extraQueryParams: [ { name:"locale" ,value:"en_GB" } , { name: "disable-funding", value:"card"}  ]
        },
        style: {
            label: 'paypal',
            size:  'small', // small | medium | large | responsive
            shape: 'rect', // pill | rect
        },
        onApprove: (data, actions) => {
          console.log(data);
          this.registerservice.userorder(this.checkoutForm.value.firstname,this.checkoutForm.value.lastname,this.checkoutForm.value.phone,this.checkoutForm.value.country,this.checkoutForm.value.address,this.checkoutForm.value.town,this.checkoutForm.value.state,this.checkoutForm.value.postalcode,this.getTotal)
          .pipe(first())
          .subscribe(
          data1 => {},
          error => {});
           
          console.log(this.getTotal);
           
            //console.log('onApprove - transaction was approved, but not authorized');
            actions.order.get().then(details => {
              this.orderService.createOrder(this.products, this.checkoutForm.value, data.orderID, this.getTotal);
                console.log('onApprove - you can get full order details inside onApprove: ', details);
            });
        },
        onClientAuthorization: (data) => {
            console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        },
        onCancel: (data, actions) => {
            console.log('OnCancel', data, actions);
        },
        onError: err => {
            console.log('OnError', err);
        },
        onClick: (data, actions) => {
         // console.log(this.products);
         // console.log(this.checkoutForm.value);
        //  console.log(this.getTotal); 
         //   console.log('onClick', data, actions);
        }
    };
  }*/




private initConfig(): void {


  this.registerservice.getsettings().pipe(first()).subscribe(
    data => {
      this.paypalclientid = data.paypalclientid;
      
      this.payPalConfig = {
        currency: this.productService.Currency.currency,
        clientId:  this.paypalclientid,
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.productService.Currency.currency,
                value: this.amount,
                breakdown: {
                  item_total: {
                    currency_code: this.productService.Currency.currency,
                    value: this.amount
                  }
                }
              },
         
            }
          ]
        },
        advanced: {
          commit: 'true',
      
        extraQueryParams: [ { name:"locale" ,value:"en_GB" } , { name: "disable-funding", value:"card"}  ]
        },
        style: {
          label: 'paypal',
          layout: 'vertical',
          size: 'responsive',
        },
        onApprove: (data, actions) => {
          console.log(data);
      
         /* actions.order.get().then(details => {
            this.orderService.createOrder(this.products, this.checkoutForm.value, data.orderID, this.getTotal);
              console.log('onApprove - you can get full order details inside onApprove: ', details);
          }); */
        },
        onClientAuthorization: (data) => {
      
          
        const userid = localStorage.getItem('excellencepwausers');
      
          this.registerservice.userorder(userid,this.checkoutForm.value.firstname,this.checkoutForm.value.lastname,this.checkoutForm.value.phone,this.checkoutForm.value.country,this.checkoutForm.value.address,this.checkoutForm.value.town,this.checkoutForm.value.state,this.checkoutForm.value.postalcode,this.amount)
          .pipe(first())
          .subscribe(
          data1 => {

            for (let i = 0; i < this.products.length; i++) {

              this.productid =  this.products[i].id;
              this.quantity =  this.products[i].quantity;
              this.price =  this.products[i].price;
              this.totalprice = this.quantity * this.price;
  
              this.registerservice.userorderdetail(data1.orderid,this.productid,this.quantity,this.price,this.totalprice)
              .pipe(first())
              .subscribe(
              data2 => {
                console.log(data2);
              },
              error => {
                console.log(error);
          
              });
              
            }
            this.orderService.createOrder();
          },
          error => {
            console.log(error);
      
          });
      
     
           
        },
        onCancel: (data, actions) => {
          console.log('OnCancel', data, actions);
        },
        onError: err => {
          console.log('OnError', err);
        },
        onClick: (data, actions) => {

          //console.log('onClick', data, actions);
        },
      };
    },

    error => {
    });





}

} 